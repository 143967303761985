import axios from "axios"

document.addEventListener("DOMContentLoaded", () => {
  const body = document.querySelector("body")
  const dialog = document.querySelector("dialog")
  const feedbackForm = dialog.querySelector("form")

  dialog.addEventListener("close", close)

  dialog.addEventListener("keydown", e => {
    if (e.keyCode === 27) {
      e.preventDefault()

      close()
    }
  })

  dialog.addEventListener("animationend", e => {
    if (e.animationName === "slideOutDown") {
      dialog.close()
      dialog.classList.remove("slideOutDown")
    }

    if (e.animationName === "bounceOutUp") {
      dialog.close()
      dialog.classList.remove("bounceOutUp")
    }
  })

  feedbackForm.addEventListener("submit", submitFeedback)

  feedbackForm.querySelector("[data-dialog-close]").addEventListener("click", close)

  document.getElementById("open-contact-form").addEventListener("click", e => {
    e.preventDefault()

    feedbackForm.reset()

    body.classList.add("noscroll")
    dialog.classList.add("animated", "slideInUp", "faster")
    dialog.showModal()
  })

  function submitFeedback(e) {
    const url = feedbackForm.dataset.url
    
    e.preventDefault()

    axios
      .post(url, document.querySelector("dialog form"), {
        headers: {"X-Requested-With": "XMLHttpRequest"},
        xsrfCookieName: "csrftoken",
        xsrfHeaderName: "X-CSRFTOKEN"
      })
      .then(response => {
        if (response.data.success) {
          dialog.classList.replace("slideInUp", "bounceOutUp")
        }

        if (response.data.errors) {
          Object.keys(response.data.errors).forEach(key => {
            const el = dialog.querySelector(`[name=${key}]`)

            const template = document.getElementById("error").content.cloneNode(true)
            const errorNode = template.querySelector(".error")
            
            errorNode.textContent = response.data.errors[key]

            el.setAttribute("novalidate", true)
            el.parentNode.appendChild(template)
          })
        }
      })
  }

  function close () {
    dialog.classList.replace("slideInUp", "slideOutDown")
    body.classList.remove("noscroll")
  }
})
